export const IMPERSONATION_CONTINUE_TO_SEARCH_PARAM = "continue-to";

/**
 * Builds urls for superadmin impersonation
 *
 * @author @Mxs2019
 */
class ImpersonationLinkGenerator {
  static makeCompanyPath(companySlug: string, continueTo?: string | null): string {
    return this.appendContinueToPath(
      `/admin/impersonation-start-company/${companySlug}`,
      continueTo
    );
  }

  static makeUserPath(userToImpersonateId: string, continueTo?: string | null): string {
    return this.appendContinueToPath(
      `/admin/impersonation-start/${userToImpersonateId}`,
      continueTo
    );
  }

  static makeEndImpersonationPath(continueTo?: string | null): string {
    return this.appendContinueToPath("/admin/impersonation-end", continueTo);
  }

  private static appendContinueToPath(path: string, continueTo?: string | null): string {
    const searchParams = new URLSearchParams();
    if (continueTo) {
      searchParams.set(IMPERSONATION_CONTINUE_TO_SEARCH_PARAM, continueTo);
    }

    return [path, searchParams.toString()].filter(Boolean).join("?");
  }
}

export default ImpersonationLinkGenerator;
